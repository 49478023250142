export class GapiDriveFolder {
    private readonly query: string;

    constructor(readonly id: string) {
        this.query = `'${this.id}' in parents and mimeType = 'application/json' and trashed = false`;
    }

    async getFiles() {
        const response = await gapi.client.drive.files.list({q: this.query});
        return response.result?.files || [];
    }

    async findFiles(name: string) {
        const response = await gapi.client.drive.files.list({q: `${this.query} and name = '${name}'`});
        return response.result?.files || [];
    }

    createFile(name: string, content: string, mimeType = 'application/json') {
        const file = new Blob([content], {type: mimeType});
        const metadata = {name, mimeType, parents: [this.id]};
        const accessToken = gapi.auth.getToken().access_token;
        const form = new FormData();
        form.append('metadata', new Blob([JSON.stringify(metadata)], {type: 'application/json'}));
        form.append('file', file);

        return fetch(`https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart`, {
            method: 'POST',
            headers: new Headers({'Authorization': 'Bearer ' + accessToken}),
            body: form,
        });
    }

    updateFile(id: string, name: string, content: string, mimeType = 'application/json') {
        const file = new Blob([content], {type: mimeType});
        const metadata = {name, mimeType};
        const accessToken = gapi.auth.getToken().access_token;
        const form = new FormData();
        form.append('metadata', new Blob([JSON.stringify(metadata)], {type: 'application/json'}));
        form.append('file', file);

        return fetch(`https://www.googleapis.com/upload/drive/v3/files/${id}?uploadType=multipart`, {
            method: 'PATCH',
            headers: new Headers({'Authorization': 'Bearer ' + accessToken}),
            body: form,
        });
    }

    async getFile<T = any>(id: string) {
        const response = await gapi.client.drive.files.get({fileId: id, alt: 'media'});
        return response.result as T;
    }
}