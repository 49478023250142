import {KeyboardArrowDown} from "@mui/icons-material";
import {IconButton, LinearProgress, Typography} from "@mui/joy";
import Table from "@mui/joy/Table";
import React from "react";
import {app} from "../AppContext";
import {IMapStats} from "../map/MapStats";

export function MapStatsView(props: {
    openDetails: { bases: boolean, decors: boolean },
    setOpenDetails: (open: { bases: boolean, decors: boolean }) => void,
}) {
    const [stats, setStats] = React.useState<IMapStats>();

    React.useEffect(() => {
        setStats(app.map.stats);
    }, []);

    app.map.onChangeStats = () =>
        setStats({...app.map.stats});

    const percent = (value: number) => {
        if (!stats?.totalCells)
            return NaN;

        return Math.round((value / (stats.totalCells)) * 100_000) / 1000;
    }

    const decorProgressPercent = (stats: IMapStats) =>
        Math.min((stats.decorationCells - stats.freeDecorationCells) / stats.decorationCells * 100, 100);

    const decorProgressColor = (stats: IMapStats) => stats.freeDecorationCells === 0
        ? "success"
        : stats.freeDecorationCells > 0 ? "warning" : "danger";

    return (
        <Table size="sm" hoverRow cellSpacing={1} sx={{
            tableLayout: "auto",
            '& tr > *:nth-child(n+3)': {textAlign: 'right'},
            '& tr > *:nth-child(1)': {width: '10%'},
            '& tr > *:nth-child(2)': {width: '40%'},
            '& tr > *:nth-child(3)': {width: '10%'},
            '& tr > *:nth-child(4)': {width: '40%'},
        }}>
            {stats && <tbody>
            <tr style={{height: 10}}>
                <td/>
                <td>Total cells</td>
                <td/>
                <td>{stats.totalCells}</td>
            </tr>
            <tr>
                <td style={{padding: 0}}>
                    <IconButton
                        variant="plain"
                        color="neutral"
                        size="sm"
                        sx={{margin: "1px"}}
                        onClick={() => props.setOpenDetails({...props.openDetails, bases: !props.openDetails.bases})}
                    >
                        <KeyboardArrowDown sx={{transform: props.openDetails.bases ? 'initial' : 'rotate(-90deg)'}}/>
                    </IconButton>
                </td>
                <td>Base cells</td>
                <td>{percent(stats.reservedBaseCells.reduce((acc, i) => acc + i, 0))}%</td>
                <td>{stats.baseCells}</td>
            </tr>
            {props.openDetails.bases && stats.baseCellsByType.map((amount, i) => (
                <tr key={i}>
                    <td style={{height: 20}}/>
                    <td style={{height: 10, paddingLeft: 10}}>{"b" + i}</td>
                    <td style={{height: 20}}>{percent(stats.reservedBaseCells[i])}%</td>
                    <td style={{height: 20}}>{amount}</td>
                </tr>
            ))}
            <tr>
                <td style={{padding: 0}}>
                    <IconButton
                        variant="plain"
                        color="neutral"
                        size="sm"
                        sx={{margin: "1px"}}
                        onClick={() => props.setOpenDetails({...props.openDetails, decors: !props.openDetails.decors})}
                    >
                        <KeyboardArrowDown sx={{transform: props.openDetails.decors ? 'initial' : 'rotate(-90deg)'}}/>
                    </IconButton>
                </td>
                <td>Decoration cells</td>
                <td>{percent(stats.decorationCells)}%</td>
                <td>{stats.decorationCells - stats.freeDecorationCells}</td>
            </tr>
            {props.openDetails.decors && <tr>
                <td style={{height: 20}} />
                <td style={{height: 20, paddingLeft: 10}} colSpan={3}>
                    <LinearProgress
                        determinate value={decorProgressPercent(stats)}
                        color={decorProgressColor(stats)}
                        size="sm"
                        thickness={16}
                    >
                        <Typography
                            level="body3"
                            fontWeight="xl"
                            textColor="common.white"
                            sx={{mixBlendMode: 'difference'}}
                        >
                            {`${stats.decorationCells - stats.freeDecorationCells} of ${stats.decorationCells}`}
                        </Typography>
                    </LinearProgress>
                </td>
            </tr>}
            </tbody>}
        </Table>
    );
}