import {CommandHistory} from "../commands/CommandHistory";
import {KeyboardController} from "../utils/KeyboardController";
import {fixDecor} from "../utils/resource_utils";
import {IMapChange, MapCommand} from "./MapCommand";
import {EMPTY_TILE, MapModel} from "./MapModel";
import {MapView} from "./MapView";

export class MapEditor {
    readonly commandHistory = new CommandHistory(100);

    private startSelectionData: IMapChange[] = [];
    private oldSelectionData: IMapChange[] = [];
    private _wasSelectionMoved: boolean = false;

    constructor(private keyCtrl: KeyboardController, private map: MapModel, private mapView: MapView) {
        map.onResetMap = () => this.commandHistory.clear();

        keyCtrl.addKeypressListener([{cmd: true}, {ctrl: true}], "Z",
            () => this.commandHistory.undo());

        keyCtrl.addKeypressListener([{cmd: true, shift: true}, {ctrl: true, shift: true}], "z",
            () => this.commandHistory.redo());
    }

    get wasSelectionMoved() {return this._wasSelectionMoved}

    setTile(x: number, y: number, type: "gnd" | "decor", img?: string) {
        const data = this.map.getTileData(x, y);
        const gnd = type === "gnd" ? img || EMPTY_TILE : data.gnd;
        const decor = type === "decor" ? fixDecor(img) : data.decor;

        if (this.map.canChangeTile(x, y, gnd, decor))
            this.commandHistory.execute(new MapCommand(this.map, [{x, y, gnd, decor}]));
    }

    setSelectedTiles(type: "gnd" | "decor", img?: string) {
        const command = new MapCommand(this.map, Array.from(this.mapView.selection).map(id => {
            const [x, y] = id.split(":").map(Number);
            const {gnd, decor} = this.map.getTileData(x, y);
            return {
                x,
                y,
                gnd: type === "gnd" ? img || EMPTY_TILE : gnd,
                decor: type === "decor" ? fixDecor(img) : decor,
            }
        }));
        this.commandHistory.execute(command);
    }

    startMoveSelection() {
        this._wasSelectionMoved = false;
        this.oldSelectionData = [];
        this.startSelectionData = Array.from(this.mapView.selection).map(id => {
            const [x, y] = id.split(":").map(Number);
            return {x, y, ...this.map.getTileData(x, y)};
        });
    }

    moveSelection(from: { x: number, y: number }, to: { x: number, y: number }, content: boolean) {
        const [dx, dy] = [to.x - from.x, to.y - from.y];
        this._wasSelectionMoved = true;

        this.mapView.selection.forEach(id => {
            const [x, y] = id.split(":").map(Number);
            this.mapView.toggleTileSelection(x, y, false);
            if (content) {
                const oldTile = this.oldSelectionData.find(it => it.x === x && it.y === y);
                const data = this.map.getTileData(x, y);
                this.map.setTile(x, y, data.gnd, oldTile?.decor);
            }
        });
        this.mapView.selection.clear();

        this.oldSelectionData = [];
        this.startSelectionData.forEach(it => {
            const [x, y] = [it.x, it.y];
            const y2 = y + dy;
            const x2 = x + dx + ((y - y2) % 2 && (from.y - y) % 2 ? (from.y % 2 ? -1 : 1) : 0);
            if (this.map.tiles[y2]?.[x2]) {
                this.mapView.selection.add(`${x2}:${y2}`);
                this.oldSelectionData.push({x: x2, y: y2, ...this.map.getTileData(x2, y2)});
                this.mapView.toggleTileSelection(x2, y2, true);

                if (content) {
                    const data = this.map.getTileData(x2, y2);
                    this.map.setTile(x2, y2, data.gnd, it.decor);
                }
            }
        });
    }

    endMoveSelection() {
        if (!this._wasSelectionMoved)
            return;

        this._wasSelectionMoved = false;
        const change: IMapChange[] = [];

        Array.from(this.mapView.selection).forEach(id => {
            const [x, y] = id.split(":").map(Number);
            change.push({x, y, ...this.map.getTileData(x, y)});

            const oldData = this.oldSelectionData.find(it => it.x === x && it.y === y);
            if (oldData)
                this.map.setTile(x, y, oldData.gnd, oldData.decor);
        });

        this.startSelectionData.forEach(it => {
            if (!change.find(c => c.x === it.x && c.y === it.y))
                change.push({x: it.x, y: it.y, ...this.map.getTileData(it.x, it.y)});

            this.map.setTile(it.x, it.y, it.gnd, it.decor);
        });

        this.commandHistory.execute(new MapCommand(this.map, change.reverse()));
    }
}
