import {EventSender} from "./EventSender";

interface IKeyboardState {
    ctrl: boolean,
    shift: boolean,
    space: boolean,
    alt: boolean,
    cmd: boolean,
}

export class KeyboardController {
    readonly onStateChanges = new EventSender<IKeyboardState>();
    private keypressListeners: { state: IKeyboardState, key: string, listener: () => void }[] = [];

    constructor() {
        window.addEventListener("keydown", this.handleKeyDown);
        window.addEventListener("keyup", this.handleKeyUp);
    }

    readonly state: IKeyboardState = {
        ctrl: false,
        shift: false,
        space: false,
        alt: false,
        cmd: false,
    }

    handleKeyDown = (e: KeyboardEvent) => {
        this.setKey(e.key, true);
        this.keypressListeners.forEach(({state, key, listener}) => {
            if (key === e.key.toLowerCase() && this.checkState(state))
                listener();
        });

    }

    handleKeyUp = (e: KeyboardEvent) => {
        this.setKey(e.key, false);
    }

    setKey = (key: string, pressed: boolean) => {
        if (key === "Control")
            this.state["ctrl"] = pressed;
        else if (key === "Shift")
            this.state["shift"] = pressed;
        else if (key === " ")
            this.state["space"] = pressed;
        else if (key === "Meta")
            this.state["cmd"] = pressed;
        else if (key === "Alt")
            this.state["alt"] = pressed;
        else
            return;

        this.onStateChanges.dispatch(this.state);
    }

    addKeypressListener(state: Partial<IKeyboardState> | Partial<IKeyboardState>[], key: string, listener: () => void) {
        const emptyState: IKeyboardState = {ctrl: false, shift: false, space: false, alt: false, cmd: false};
        const states = Array.isArray(state) ? state : [state];
        for (const s of states) {
            this.keypressListeners.push({state: {...emptyState, ...s}, key: key.toLowerCase(), listener});
        }
    }

    // noinspection JSUnusedGlobalSymbols
    removeKeypressListener(listener: () => void) {
        this.keypressListeners = this.keypressListeners.filter(l => l.listener !== listener);
    }

    private checkState(state: IKeyboardState) {
        return !(Object.keys(this.state) as [keyof IKeyboardState]).some(key => state[key] !== this.state[key]);
    }
}