import {Checkbox, Grid, Table} from "@mui/joy";
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import * as React from "react";
import {BASE_CELL_TYPES, IMapProps} from "../map/MapModel";
import {AppModal} from "./AppModal";
import {CloseButton} from "./CloseButton";

export function MapPropsDialog(props: {
    data?: IMapProps & { decorationsCells: number },
    onClose: (data?: IMapProps) => void,
}) {
    const calcTotalCells = (size: number) => {
        return size > 0 ? ((size * 2 - 1) ** 2 + 1) / 2 : 0;
    }

    const round = (v: number) => Math.round(v * 1000) / 1000;
    const fromPercent = (v: number) => Math.floor(v * totalCells / 100);

    const [name, setName] = React.useState(props.data?.name || "");
    const [size, setSize] = React.useState(props.data?.size);
    const [readOnlyBaseTiles, setReadOnlyBaseTiles] = React.useState(props.data?.readOnlyBaseTiles || false);
    const [totalCells, setTotalCells] = React.useState(calcTotalCells(props.data?.size || 0));

    const decorCells = props.data?.decorationsCells || 0;
    const percentsByTypes = props.data?.baseCellsByType || BASE_CELL_TYPES.map(() => 0);
    const [baseCells, setBaseCells] = React.useState(percentsByTypes.reduce((acc, v) => acc + fromPercent(v), 0));
    const [baseCellsByType, setBaseCellsByType] = React.useState(percentsByTypes);

    const handleSizeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const size = event.target.valueAsNumber;
        const totalCells = calcTotalCells(size);
        if (totalCells >= baseCells + decorCells) {
            setTotalCells(totalCells);
            setSize(size);
        }
    }

    const handleBaseTypePercentInput = (i: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Math.max(0, event.target.valueAsNumber);
        const values = baseCellsByType.slice();
        values[i] = value;

        const sum = values.reduce((acc, v) => acc + v, 0);
        if (sum > 100)
            values[i] = round(Math.max(100 - sum + value, 0));

        setBaseCellsByType(values);
        setBaseCells(values.reduce((acc, v) => acc + fromPercent(v), 0));
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        props.onClose({
            name: name,
            size: size || 0,
            readOnlyBaseTiles,
            baseCellsByType,
        });
    }

    return (
        <AppModal open={true} onClose={() => props.onClose()}>
            <ModalDialog
                aria-labelledby="basic-modal-dialog-title"
                aria-describedby="basic-modal-dialog-description"
                sx={{maxWidth: 500}}
            >
                <CloseButton onClick={props.onClose}/>
                <Typography id="basic-modal-dialog-title" component="h2">
                    {props.data ? "Edit map" : "New map"}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input autoFocus
                                   required
                                   value={name}
                                   onInput={e => setName((e.target as HTMLInputElement).value)}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Size</FormLabel>
                            <Input required
                                   type="number"
                                   value={size}
                                   onInput={handleSizeInput}/>
                        </FormControl>

                        <Table>
                            <thead>
                            <tr>
                                <th>Type</th>
                                <th>Cells number</th>
                                <th>Cells %</th>
                            </tr>
                            </thead>
                            <tbody>
                            {baseCellsByType.map((value, i) => (
                                <tr key={i}>
                                    <td>{`b${i}`}</td>
                                    <td>{Math.floor(value * totalCells / 100)}</td>
                                    <td>
                                        <Input name={`b${i}`} value={value} type="number" required
                                               disabled={props.data?.readOnlyBaseTiles}
                                               onInput={event => handleBaseTypePercentInput(i, event as any)}
                                        />
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td>Decors</td>
                                <td>{totalCells - baseCells}</td>
                                <td>{round(baseCellsByType.reduce((acc, v) => acc - v, 100))}</td>
                            </tr>
                            </tfoot>
                        </Table>

                        {props.data && <FormControl>
                            <Checkbox label="Readonly base tiles"
                                      checked={readOnlyBaseTiles}
                                      disabled={props.data?.readOnlyBaseTiles}
                                      onChange={e => setReadOnlyBaseTiles(e.target.checked)}
                            />
                        </FormControl>}
                        <Grid container spacing={1}>
                            <Grid xs={4}>
                                <Typography>Total cells: {totalCells}</Typography>
                            </Grid>
                            <Grid xs={4}>
                                <Typography>Base cells: {baseCells}</Typography>
                            </Grid>
                            <Grid xs={4}>
                                <Typography>Used decors: {props.data?.decorationsCells || 0}</Typography>
                            </Grid>
                        </Grid>
                        <Stack spacing={2} direction="row" justifyContent="flex-end">
                            <Button variant="outlined" color="neutral" onClick={() => props.onClose()}>
                                Cancel
                            </Button>
                            <Button variant="outlined" type="submit">
                                {props.data ? "Update" : "Create"}
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </ModalDialog>
        </AppModal>
    );
}