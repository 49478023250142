import {app} from "../AppContext";
import {checkResource, makeResourceName} from "../utils/resource_utils";
import {EMPTY_TILE, ITile, MapModel} from "./MapModel";
import {MapPhantom} from "./MapPhantom";

const GND_PATH = "/resources/gnd";
const DECOR_PATH = "/resources/tiles";
const BASE_PATH = "/resources/bases";
const TILE_WIDTH = 200;
const TILE_HEIGHT = 116;

export class MapView {
    private tiles: HTMLElement[][] = [];
    readonly selection: Set<string> = new Set();
    private surface?: HTMLDivElement;
    private zoom: number = 1;
    private selectionOpacity: number = 1;
    private onChangeSelection: (tiles: ITile[]) => void = () => {};

    constructor(private map: MapModel, private phantom: MapPhantom) {
        requestAnimationFrame(this.refreshSelection);
        map.onChangeTile = this.handleChangeTile;
    }

    get width() {return this.map.size * TILE_WIDTH}

    get height() {return this.map.size * TILE_HEIGHT}

    set changeSelectionHandler(handler: (tiles: ITile[]) => void) {
        this.onChangeSelection = handler;
    }

    selectTile(coords: { x: number, y: number }, add: boolean) {
        const {x, y} = coords;
        const id = `${x}:${y}`

        if (add) {
            this.selection.has(id) ? this.selection.delete(id) : this.selection.add(id);
            this.toggleTileSelection(x, y, this.selection.has(`${x}:${y}`));
        } else {
            this.selection.forEach(id => {
                const [x, y] = id.split(":").map(Number);
                this.toggleTileSelection(x, y, false);
            });
            this.selection.clear();
            this.selection.add(id);
            this.toggleTileSelection(x, y, true);
        }
        this.onChangeSelection(this.getSelectedTiles());
    }

    handleChangeTile = (x: number, y: number) => {
        const data = this.map.getTileData(x, y);
        const gndIsValid = checkResource("gnd", data.gnd);
        const decorIsValid = !data.decor || checkResource("decor", data.decor);

        const tileEl = this.tiles[y][x];
        tileEl.style.backgroundImage = `url('${GND_PATH}/${makeResourceName(data.gnd)}.png')`

        if (tileEl.firstChild?.nodeName === "SPAN" && gndIsValid && decorIsValid)
            tileEl.firstChild.remove();

        if (tileEl.firstChild?.nodeName === "IMG")
            tileEl.firstChild.remove();

        if (data.decor && gndIsValid && decorIsValid) {
            const img = document.createElement("img");
            img.src = `${DECOR_PATH}/${makeResourceName(data.decor)}.png`;
            img.onerror = this.onImgError;
            tileEl.insertBefore(img, tileEl.firstChild);
        }

        if (this.selection.has(`${x}:${y}`))
            this.onChangeSelection(this.getSelectedTiles());
    }

    setSurface(surface: HTMLDivElement) {
        this.surface = surface;
        this.buildMap();
    }

    buildMap() {
        if (!this.surface)
            return;

        const surface = this.surface;

        surface.innerHTML = "";
        this.tiles = [];
        this.selection.clear();

        this.updateSurfaceStyle();
        this.map.tiles.forEach((row) => {
            const rowEl = document.createElement("div");
            rowEl.className = "row";
            surface.appendChild(rowEl);

            const line: HTMLElement[] = [];
            this.tiles.push(line);

            row.forEach((tile) => {
                const [gnd, decor] = (tile.img?.split(":") || [EMPTY_TILE]) as [string, string | undefined];
                const gndIsValid = checkResource("gnd", gnd);
                const decorIsValid = !decor || checkResource("decor", decor);

                const tileEl = document.createElement("div");
                tileEl.className = "tile";
                tileEl.style.opacity = this.map.isTileReadonly(tile) ? "0.85" : "1";
                rowEl.appendChild(tileEl);
                line.push(tileEl);

                if (gndIsValid)
                    tileEl.style.backgroundImage = `url('${GND_PATH}/${makeResourceName(gnd)}.png')`

                if (decor && gndIsValid && decorIsValid) {
                    const img = document.createElement("img");
                    img.src = `${DECOR_PATH}/${makeResourceName(decor)}.png`;
                    img.alt = " ";
                    img.onerror = this.onImgError;
                    tileEl.appendChild(img);
                } else if (!gndIsValid || !decorIsValid) {
                    const label = document.createElement("span");
                    label.className = "label error";
                    label.innerHTML = "NOT FOUND";
                    label.style.color = "red";
                    label.style.paddingTop = "12px";
                    tileEl.insertBefore(label, tileEl.firstChild);
                } else {
                    const base = this.phantom.getBase(tile);
                    if (base) {
                        const div = document.createElement("div");
                        div.className = "base";
                        const img = document.createElement("img");
                        img.src = `${BASE_PATH}/${base}.png`;
                        img.alt = " ";
                        img.onerror = this.onImgError;
                        div.appendChild(img);
                        tileEl.appendChild(div);
                    }
                }

                if (app.state.showLabels) {
                    const label = document.createElement("div");
                    label.className = "label";
                    label.innerHTML = tile[app.state.showLabels];
                    tileEl.appendChild(label);
                }
            });
        });
    }

    setZoom(zoom: number) {
        this.zoom = zoom;
        this.updateSurfaceStyle();
    }

    showLabels(show: false | "id" | "type") {
        app.updateState({showLabels: show});
        this.tiles.forEach((r, j) => r.forEach((t, i) => {
            if (show) {
                let label = t.lastChild as HTMLDivElement;
                if (t.lastChild?.nodeName !== "DIV") {
                    label = document.createElement("div");
                    label.className = "label";
                    t.appendChild(label);
                }
                label.innerHTML = this.map.tiles[j][i][show];
            } else if (t.lastChild?.nodeName === "DIV") {
                t.lastChild.remove();
            }
        }));
    }

    getTileCoords(surface: { x: number, y: number }) {
        const ratio = TILE_HEIGHT / TILE_WIDTH;
        let {x: surfaceX, y: surfaceY} = surface;

        let x = Math.floor(surface.x / TILE_WIDTH);
        let y = Math.floor(surface.y / TILE_HEIGHT) * 2;
        let extraX = Math.abs(TILE_WIDTH / 2 - surfaceX % TILE_WIDTH);
        let extraY = Math.abs(TILE_HEIGHT / 2 - surfaceY % TILE_HEIGHT);
        let hit = extraX * ratio + extraY < TILE_HEIGHT / 2;

        if (!hit) {
            surfaceX -= TILE_WIDTH / 2;
            surfaceY -= TILE_HEIGHT / 2;
            x = Math.floor(surfaceX / TILE_WIDTH);
            y = Math.floor(surfaceY / TILE_HEIGHT) * 2 + 1;
            extraX = Math.abs(TILE_WIDTH / 2 - surfaceX % TILE_WIDTH);
            extraY = Math.abs(TILE_HEIGHT / 2 - surfaceY % TILE_HEIGHT);
            hit = extraX * ratio + extraY < TILE_HEIGHT / 2;
        }

        return hit && y < this.tiles.length && x < this.tiles[y].length ? {x, y} : undefined;
    }

    refreshSelection = (time: number) => {
        this.selectionOpacity = 0.6 + 0.2 * Math.sin(time / 1000 * Math.PI);
        this.selection.forEach(id => {
            const [x, y] = id.split(":").map(Number);
            this.toggleTileSelection(x, y, true);
        });
        requestAnimationFrame(this.refreshSelection);
    }

    toggleTileSelection(x: number, y: number, selected: boolean) {
        this.tiles[y][x].style.filter = selected ? `drop-shadow(0 0 5px rgba(255,0,0,${this.selectionOpacity}))` : "none";
        this.tiles[y][x].style.zIndex = selected ? "1" : "0";
    }

    private getSelectedTiles() {
        return Array.from(this.selection).map(id => {
            const [x, y] = id.split(":").map(Number);
            return this.map.tiles[y][x];
        });
    }

    private updateSurfaceStyle() {
        const w = TILE_WIDTH * this.zoom;
        const h = TILE_HEIGHT * this.zoom;
        const rowWidth = w * this.map.size;
        const rowHeight = h * 0.5;
        const labelSize = 10 * Math.sqrt(this.zoom);
        const style = document.createElement("style");
        style.id = "map-style";
        style.innerHTML = `
            .surface .row {
                display: flex;
                flex-direction: row;
                position: relative;
                width: ${rowWidth}px;
                height: ${rowHeight}px;
            }
            .surface .row:nth-child(even) {
                left: ${w * 0.5}px;
                width: ${rowWidth - h}px;
            }
            .surface .row .tile {
                display: inline-block;
                width: ${w}px;
                height: ${h}px;
                background-size: contain;
                background-repeat: no-repeat;
            }
            .surface .row .tile img {
                position: absolute;
                height: ${h}px;
                -webkit-user-drag: none;
            }
            .surface .row .tile .base {
                position: absolute;
                width: ${w}px;
                height: ${h}px;
                text-align: center;
            }
            .surface .row .tile .base img {
                position: static;
            }
            .surface .row .tile .label {
                position: absolute;
                top: ${h / 2 - labelSize}px;
                height: 20px;
                width: ${w}px;
                font-size: ${labelSize}px;
                font-weight: bold;
                text-align: center;
                text-shadow: 0 0 5px var(--joy-palette-background-surface);
                color: var(--joy-palette-neutral-plainColor);
                z-index: 1;
            }`;

        document.getElementById("map-style")?.remove();
        document.head.appendChild(style);
    }

    onImgError = (e: Event | string) => {
        if (typeof e === "string")
            return;

        const img = e.target as HTMLImageElement;
        const tileEl = img.parentElement;
        if (tileEl) {
            img.remove();

            const label = document.createElement("span");
            label.className = "label error";
            label.innerHTML = "IMG ERROR";
            label.style.color = "red";
            label.style.paddingTop = "12px";
            tileEl.insertBefore(label, tileEl.firstChild);
        }
    }
}
