import {Close} from "@mui/icons-material";
import {IconButton} from "@mui/joy";
import * as React from "react";

export function CloseButton(props: { onClick: () => void }) {
    return (
        <IconButton size="sm" color="danger" variant="outlined"
                    sx={{position: "absolute", right: 8, top: 8}}
                    onClick={() => props.onClick()}>
            <Close/>
        </IconButton>
    )
}