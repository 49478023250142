import {AppStorage, IAppState} from "./AppStorage";
import {EAlertSeverity} from "./components/AppAlert";
import {GapiClient} from "./gapi/GapiClient";
import {GapiDriveFolder} from "./gapi/GapiDriveFolder";
import {MapEditor} from "./map/MapEditor";
import {MapModel} from "./map/MapModel";
import {MapPhantom} from "./map/MapPhantom";
import {MapView} from "./map/MapView";
import { GoogleDriveService } from "./services/GoogleDriveService";
import {KeyboardController} from "./utils/KeyboardController";

class AppContext {
    readonly storage: AppStorage;
    readonly state: IAppState;
    readonly map: MapModel;
    readonly mapPhantom: MapPhantom;
    readonly googleDriveService: GoogleDriveService;
    readonly mapView: MapView;
    readonly keyCtrl: KeyboardController;
    readonly mapEditor: MapEditor;
    readonly gapiClient: GapiClient;
    readonly driveFolder: GapiDriveFolder;

    private _initSaveMapTimeout?: NodeJS.Timeout;

    constructor() {
        if (!process.env.REACT_APP_DRIVE_FOLDER_ID)
            throw new Error("REACT_APP_DRIVE_FOLDER_ID is not defined");

        if (!process.env.REACT_APP_GOOGLE_CLIENT_ID)
            throw new Error("REACT_APP_GOOGLE_CLIENT_ID is not defined");

        this.storage = new AppStorage();
        this.state = this.storage.loadAppState();
        this.map = new MapModel();
        this.googleDriveService = new GoogleDriveService(process.env.REACT_APP_DRIVE_FOLDER_ID!);
        this.mapPhantom = new MapPhantom(this.map, this.storage.loadPhantomData());
        this.mapView = new MapView(this.map, this.mapPhantom);
        this.keyCtrl = new KeyboardController();
        this.mapEditor = new MapEditor(this.keyCtrl, this.map, this.mapView);
        this.gapiClient = new GapiClient();
        this.driveFolder = new GapiDriveFolder(process.env.REACT_APP_DRIVE_FOLDER_ID);

        this.initMap();
        this.mapPhantom.onChange = (data) => this.storage.savePhantomData(data);
    }

    updateState(state: Partial<IAppState>) {
        Object.assign(this.state, state);
        this.storage.saveAppState(this.state);
    }

    showSuccess(text: string) {
        this.onShowAlert(text, EAlertSeverity.success);
    }

    showError(text: string, error?: unknown) {
        const parts = [text];
        let errorMessage = error?.toString();
        if (error instanceof Error)
            errorMessage = error.message;

        if (errorMessage)
            parts.push(errorMessage);

        this.onShowAlert(parts.join(", "), EAlertSeverity.error);
    }

    saveMapInStorage() {
        this.saveMapNow();
    }

    onShowAlert: (text: string, severity: EAlertSeverity) => void = () => {};

    // onConfirm: (text: string) => Promise<boolean> = () => Promise.resolve(true);

    private initMap() {
        const mapData = this.storage.loadMapData();
        if (mapData)
            this.map.setMap(mapData);
        else
            this.map.initMap(100, "New Map");

        this.map.onChange = this.saveMap;
    }

    private saveMap = (props?: { minor: boolean }) =>
    {
        if (this._initSaveMapTimeout)
            clearTimeout(this._initSaveMapTimeout);

        if (!props?.minor) {
            this.saveMapNow();
            return;
        }

        this._initSaveMapTimeout = setTimeout(() => {
            this._initSaveMapTimeout = undefined;
            this.saveMapNow();
        }, 1000);
    }

    private saveMapNow() {
        this.storage.saveMapData(this.map.getMap());
    }

}

export const app = new AppContext();