import {Login, Logout} from "@mui/icons-material";
import {Avatar, Divider, IconButton, Menu, MenuItem, Stack, Typography} from "@mui/joy";
import {Box} from "@mui/material";
import React from "react";
import {app} from "../AppContext";
import {IProfile} from "../gapi/GapiClient";

export function LoginControls(props: { onLoggedInChange?: (loggedIn: boolean) => void }) {
    const [profile, setProfile] = React.useState<IProfile | undefined>(app.gapiClient.profile);
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement>();

    app.gapiClient.onAuthChange = (profile) => {
        setProfile(profile);
        props.onLoggedInChange?.(profile !== undefined);
    }

    const login = () => {
        app.gapiClient.login().catch(console.error);
    }

    const logout = () => {
        app.gapiClient.logout().catch(console.error);
        setMenuAnchorEl(undefined);
    }

    const handleAvatarClick = (e: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(e.currentTarget);
    }

    return (<Box>
        {profile
            ? <Avatar
                variant="outlined"
                src={profile.picture}
                alt={profile.name}
                onClick={handleAvatarClick}
                sx={{cursor: "pointer"}}
            />
            : <IconButton variant="outlined" onClick={login}><Login/></IconButton>
        }
        <Menu
            anchorEl={menuAnchorEl}
            open={menuAnchorEl !== undefined}
            onClose={() => setMenuAnchorEl(undefined)}
        >
            <MenuItem>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography level="body1">{profile?.name}</Typography>
                    <Divider orientation="vertical"/>
                    <IconButton size="sm" variant="outlined" onClick={logout}><Logout/></IconButton>
                </Stack>
            </MenuItem>
        </Menu>
    </Box>);
}