import {IUndoableCommand} from "../commands/CommandHistory";
import {MapModel} from "./MapModel";

export interface IMapChange {
    x: number;
    y: number;
    gnd: string;
    decor?: string;
}

export class MapCommand implements IUndoableCommand {
    private oldData: IMapChange[] = [];
    private newData: IMapChange[] = [];

    constructor(readonly map: MapModel, changes: IMapChange[]) {
        this.oldData = changes.map(({x, y}) => ({x, y, ...map.getTileData(x, y)}));
        this.newData = changes;
    }

    execute() {
        this.newData.forEach((i) => {
            this.map.setTile(i.x, i.y, i.gnd, i.decor);
        });
    }

    undo() {
        this.oldData.forEach((i) => {
            this.map.setTile(i.x, i.y, i.gnd, i.decor, true);
        });
    }
}