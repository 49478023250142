import {AspectRatio, Sheet, Stack} from "@mui/joy";
import Table from "@mui/joy/Table";
import React from "react";
import {ITile} from "../map/MapModel";
import {makeResourceName} from "../utils/resource_utils";

export function SelectedItemView(props: { item: ITile | undefined }) {
    const [gnd, decor] = props.item?.img?.split(":") || [undefined, undefined];
    const gndResource = `/gnd/${makeResourceName(gnd)}.png`;
    const decorResource = `/tiles/${makeResourceName(decor)}.png`;

    return (
        <Sheet variant="plain">
            <Stack direction="column" padding={1}>
                <AspectRatio ratio="512/296" maxHeight="200px" minHeight="116px" variant="plain">
                    {props.item && <div>
                        <img style={{position: "absolute"}}
                             width={200}
                             src={"/resources" + gndResource}
                             alt={gndResource}
                        />
                        {decor && <img style={{position: "absolute"}}
                                       width={200}
                                       src={"/resources" + decorResource}
                                       alt={decorResource}
                        />}
                    </div>}
                </AspectRatio>
                <Table size="sm" hoverRow sx={{tableLayout: "auto"}}>
                    <tbody>
                    <tr>
                        <td style={{width: "30%"}}>Id</td>
                        <td>{props.item?.id}</td>
                    </tr>
                    <tr>
                        <td style={{width: "30%"}}>Type</td>
                        <td>{props.item?.type}</td>
                    </tr>
                    <tr>
                        <td style={{width: "30%"}}>Img</td>
                        <td>{props.item?.img}</td>
                    </tr>
                    </tbody>
                </Table>
            </Stack>
        </Sheet>);
}