import {IMap} from "./map/MapModel";
import { TPhantomData } from "./map/MapPhantom";
import {isBrowser} from "./utils/app_util";

export interface IAppState {
    showLabels: false | "id" | "type";
    phantomPercent?: number;
}

const state_key = "app-state";
const map_key = "map-data";
const phantom_key = "phantom-data";

export class AppStorage {

    loadAppState(): IAppState {
        const data = isBrowser()
            ? localStorage.getItem(state_key)
            : null;

        const json = data ? JSON.parse(data) : {};

        return {
            showLabels: json?.showLabels ?? false,
            phantomPercent: json?.phantomPercent ?? 0,
        };
    }

    saveAppState(data: IAppState) {
        if (isBrowser()) {
            localStorage.setItem(state_key, JSON.stringify(data));
        }
    }

    loadMapData(): IMap | null {
        const data = isBrowser()
            ? localStorage.getItem(map_key)
            : null;

        return data ? JSON.parse(data) : null;
    }

    saveMapData(data: IMap) {
        if (isBrowser()) {
            localStorage.setItem(map_key, JSON.stringify(data));
        }
    }

    loadPhantomData(): TPhantomData | null {
        const data = isBrowser()
            ? localStorage.getItem(phantom_key)
            : null;

        return data ? JSON.parse(data) : null;
    }

    savePhantomData(data: TPhantomData) {
        if (isBrowser()) {
            localStorage.setItem(phantom_key, JSON.stringify(data));
        }
    }
}