import { GapiDriveFolder } from "../gapi/GapiDriveFolder";
import { IMap } from "../map/MapModel";

export class GoogleDriveService {
	private readonly _driveFolder: GapiDriveFolder;

	constructor(folderId: string) {
		this._driveFolder = new GapiDriveFolder(folderId);
	}

	get driveFolder() {return this._driveFolder}

	async loadFilesOfMaps() {
		try {
			return await this.driveFolder.getFiles();
		} catch (e) {
			console.error(e);
		}
	}

	async findFilesOfMap(name: string) {
		try {
			return await this.driveFolder.findFiles(name);
		} catch (e) {
			console.error(e);
		}
	}

	async loadMap(id: string) {
		let mapData: IMap | undefined;
		try {
			mapData = await this.driveFolder.getFile<IMap>(id);
			return mapData;
		} catch (e) {
			console.error(e);
		}
	}

	async updateMapFile(id: string, name: string, content: string) {
		try {
			await this.driveFolder.updateFile(id, name, content);
		} catch (e) {
			console.error(e);
		}
	}

	async createMapFile(name: string, content: string) {
		try {
			await this.driveFolder.createFile(name, content);
		} catch (e) {
			console.error(e);
		}
	}
}