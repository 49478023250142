export class EventSender<T = undefined> {
    private readonly listeners: ((event?: T) => void)[] = [];

    addListener(listener: (event?: T) => void) {
        this.listeners.push(listener);
    }

    removeListener(listener: (event?: T) => void) {
        const index = this.listeners.indexOf(listener);
        if (index >= 0)
            this.listeners.splice(index, 1);
    }

    // noinspection JSUnusedGlobalSymbols
    removeAllListeners() {
        this.listeners.splice(0, this.listeners.length);
    }

    dispatch(event?: T) {
        this.listeners.forEach(l => l(event));
    }
}