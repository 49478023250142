import {Stack, Tab, TabList, TabPanel, Tabs} from "@mui/joy";
import React from "react";
import gnd from "../conf/gnd.json";
import tiles from "../conf/tiles.json";
import {ITile} from "../map/MapModel";
import {MapStatsView} from "./MapStatsView";
import {SelectedItemView} from "./SelectedItemView";
import {TilesList} from "./TilesList";

export function PropsPanel(props: {
    selection: ITile[],
    onSelectDecor: (img: string) => void,
    onSelectGnd: (img: string) => void,
}) {
    const [openDetails, setOpenDetails] = React.useState({bases: true, decors: true});

    return (<Stack
        direction="column"
        height="100%"
    >
        <Stack direction="column">
            <Tabs size="sm" defaultValue={0}>
                <TabList size="sm">
                    <Tab>Selected Item</Tab>
                    <Tab>Map Stats</Tab>
                </TabList>
                <TabPanel size="sm" value={0}>
                    <SelectedItemView item={props.selection[0]}/>
                </TabPanel>
                <TabPanel value={1}>
                    <MapStatsView openDetails={openDetails} setOpenDetails={setOpenDetails}/>
                </TabPanel>
            </Tabs>
        </Stack>
        <Stack direction="column" sx={{overflow: "auto", mt: 1}}>
            <Tabs size="sm" defaultValue={0} sx={{height: "100%"}}>
                <TabList size="sm">
                    <Tab>Tiles</Tab>
                    <Tab>Grounds</Tab>
                </TabList>
                <TabPanel size="sm" value={0} sx={{height: "calc(100% - 40px)"}}>
                    <TilesList type="decor" tiles={tiles} onSelect={props.onSelectDecor}/>
                </TabPanel>
                <TabPanel size="sm" value={1} sx={{height: "calc(100% - 40px)"}}>
                    <TilesList type="gnd" tiles={gnd} onSelect={props.onSelectGnd}/>
                </TabPanel>
            </Tabs>
        </Stack>
    </Stack>);
}
