import {EventSender} from "../utils/EventSender";

export interface IUndoableCommand {
    undo(): void;
    execute(): void;
}

export class CommandHistory {
    readonly changeEvent = new EventSender();
    private readonly maxUndo: number;
    private execList: IUndoableCommand[] = [];
    private undoList: IUndoableCommand[] = [];

    constructor(maxUndo: number) {
        this.maxUndo = maxUndo;
    }

    execute(command: IUndoableCommand) {
        command.execute();
        this.execList.push(command);
        this.undoList = [];
        if (this.execList.length > this.maxUndo)
            this.execList.shift();

        this.changeEvent.dispatch();
    }

    undo() {
        const command = this.execList.pop();
        if (command) {
            command.undo();
            this.undoList.push(command);
            this.changeEvent.dispatch();
        }
    }

    get canUndo() {
        return this.execList.length > 0;
    }

    redo() {
        const command = this.undoList.pop();
        if (command) {
            command.execute();
            this.execList.push(command);
            this.changeEvent.dispatch();
        }
    }

    get canRedo() {
        return this.undoList.length > 0;
    }

    clear() {
        this.execList = [];
        this.undoList = [];
        this.changeEvent.dispatch();
    }
}