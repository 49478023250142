import tiles from "../conf/tiles.json";
import gnd from "../conf/gnd.json";

const RES: { decor: string[], gnd: string[] } = {
    decor: (tiles as any[]).reduce((all, t) => [...all, ...(t instanceof Array ? t : [t])], []),
    gnd: (gnd as any[]).reduce((all, t) => [...all, ...(t instanceof Array ? t : [t])], []),
};

export function makeResourceName(name: string | undefined) {
    const modIndex = (name || "").lastIndexOf("_");
    return modIndex < 0 || name?.[modIndex + 1] === "e" ? name : name?.substring(0, modIndex) + '/' + name;
}

export function checkResource(type: "gnd" | "decor", name: string): boolean {
    return RES[type].includes(name) && name !== "empty";
}

export function fixDecor(decor: string | undefined) {
    return decor === "empty" ? undefined : decor;
}