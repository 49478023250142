import {CssBaseline, CssVarsProvider, Sheet, Stack} from "@mui/joy";
import React from "react";
import {app} from "./AppContext";
import {AppAlert, EAlertSeverity} from "./components/AppAlert";
import {ControlsPanel} from "./components/ControlsPanel";
import {Editor, ITileMod} from "./components/Editor";
import {PropsPanel} from "./components/PropsPanel";
import {ITile} from "./map/MapModel";

export function App() {
    const [tileMod, setTileMod] = React.useState<ITileMod | undefined>(undefined);
    const [selection, setSelection] = React.useState<ITile[]>([]);
    const [alertState, setAlertState] = React.useState({open: false, msg: "", severity: EAlertSeverity.info});
    const handleCloseAlert = () => {
        setAlertState({...alertState, open: false});
    };

    app.onShowAlert = ((text, severity) => {
        setAlertState({open: true, msg: String(text), severity});
    })

    return (
        <CssVarsProvider>
            <CssBaseline enableColorScheme/>
            <Sheet sx={{height: "100%"}}>
                <Stack height="100%">
                    <ControlsPanel/>
                    <AppAlert open={alertState.open} msg={alertState.msg} severity={alertState.severity}
                              autoCloseDuration={5000}
                              onClose={handleCloseAlert}
                    />
                    <Stack
                        direction="row"
                        margin={1}
                        spacing={1}
                        height="100%"
                        overflow="auto"
                    >
                        <Editor tileMod={tileMod} onSelectionChange={setSelection}/>
                        <PropsPanel selection={selection}
                                    onSelectDecor={(img) => setTileMod({type: "decor", img})}
                                    onSelectGnd={(img) => setTileMod({type: "gnd", img})}
                        />
                    </Stack>
                </Stack>
            </Sheet>
        </CssVarsProvider>
    );
}
