import {Switch, useColorScheme} from "@mui/joy";
import {SxProps} from "@mui/joy/styles/types";
import React from "react";

export function ColorModeSwitcher(props: { sx?: SxProps }) {
    const {mode, setMode} = useColorScheme();

    return (
        <Switch
            endDecorator={mode === "dark" ? "Dark" : "Light"}
            variant="outlined"
            checked={mode === "dark"}
            onChange={(e) => setMode(mode === "dark" ? "light" : "dark")}
            sx={props.sx}
        />
    );
}
