import {ITile, MapModel} from "./MapModel";
import BASES from "../conf/bases.json";

export type TPhantomData = { [k: string]: string; };

export class MapPhantom {
    private readonly bases = new Map<string, string>();
    private _onChange: (data: TPhantomData) => void = () => {};

    constructor(private map: MapModel, savedData: TPhantomData | null) {
        if (savedData)
            this.bases = new Map(Object.entries(savedData));
    }

    set onChange(handler: (data: TPhantomData) => void) {
        this._onChange = handler;
    }

    update(percent: number, reset = false) {
        if (reset)
            this.bases.clear();

        const baseTiles = this.map.tiles.flat().filter((tile) => tile.type.startsWith("b"));
        const baseCount = Math.floor(baseTiles.length * percent / 100);

        while (this.bases.size > baseCount) {
            this.bases.delete(this.bases.keys().next().value);
        }

        if (this.bases.size < baseCount) {
            const baseTilesLeft = baseTiles.filter((tile) => !this.bases.has(tile.id));
            while (this.bases.size < baseCount) {
                const tile = baseTilesLeft[Math.floor(Math.random() * baseTilesLeft.length)];
                this.bases.set(tile.id, BASES[Math.floor(Math.random() * BASES.length)]);
            }
        }

        this._onChange(Object.fromEntries(this.bases));
    }

    getBase(tile: ITile) {
        return this.bases.get(tile.id);
    }
}