const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest';
const SCOPES = [
    "profile", "email",
    // View metadata for files in your Drive. (Restricted)
    "https://www.googleapis.com/auth/drive.metadata.readonly",
    // View and manage Drive files and folders that you open or create with an app. (Non-sensitive)
    "https://www.googleapis.com/auth/drive.file",
    "https://www.googleapis.com/auth/drive.resource",
    // View and manage all of your Drive files. (Restricted)
    "https://www.googleapis.com/auth/drive",
];

export interface IProfile {
    sub: string;
    name: string;
    given_name: string;
    family_name: string;
    picture: string;
    email: string;
    email_verified: boolean;
    locale: string;
}

export class GapiClient {
    private tokenClient?: google.accounts.oauth2.TokenClient;
    private _profile: IProfile | undefined;
    private _onAuthChange: (profile: IProfile | undefined) => void = () => {};

    constructor() {
        this.initGapi().catch(console.error);
    }

    get profile() {return this._profile}

    set onAuthChange(handler: (profile: IProfile | undefined) => void) {
        this._onAuthChange = handler;
    }

    async initGapi() {
        console.log("initGapi");
        gapi.load("client", async () => {
            await gapi.client.init({
                discoveryDocs: [DISCOVERY_DOC],
            });

            if (gapi.client.getToken() === null) {
                if (this.tokenClient === undefined)
                    throw new Error("tokenClient is undefined");

                const access = JSON.parse(sessionStorage.getItem('access') || '{}');
                if (access?.token && (access?.expiresAt || 0) > Date.now()) {
                    gapi.client.setToken({access_token: access.token})
                    await this.getProfile();
                } else {
                    this.tokenClient.requestAccessToken({prompt: ''});
                }
            } else {
                await this.getProfile();
            }
        });

        this.tokenClient = google.accounts.oauth2.initTokenClient({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
            scope: SCOPES.join(" "),
            callback: this.tokenClientCallback,
        });
    }

    checkAccess() {
        const access = JSON.parse(sessionStorage.getItem('access') || '{}');
        if (!access?.token || (access?.expiresAt || 0) < Date.now()) {
            this.tokenClient?.requestAccessToken({prompt: ''});
        }
    }

    async getProfile() {
        console.log("loadProfile");
        try {
            const response = await fetch(`https://www.googleapis.com/oauth2/v3/userinfo`, {
                headers: new Headers({
                    'Authorization': 'Bearer ' + gapi.auth.getToken().access_token,
                    'Accept': 'application/json',
                }),
            });

            const profile = await response.json();
            if (!profile.error) {
                this._profile = profile;
                this._onAuthChange(this._profile);
            }
        } catch (e) {
            console.error(e);
        }
    }

    async login() {
        if (this.tokenClient === undefined)
            throw new Error("tokenClient is undefined");

        if (gapi.client.getToken() === null) {
            // Prompt the user to select a Google Account and ask for consent to share their data
            // when establishing a new session.
            this.tokenClient.requestAccessToken({prompt: 'consent'});
        } else {
            // Skip display of account chooser and consent dialog for an existing session.
            this.tokenClient.requestAccessToken({prompt: ''});
        }
    }

    async logout() {
        sessionStorage.clear();
        const token = gapi.client.getToken();
        if (token !== null) {
            google.accounts.oauth2.revoke(token.access_token, () => console.log("access_token revoked"));
            gapi.client.setToken(null);
        }
        this._profile = undefined;
        this._onAuthChange(this._profile);
    }

    private tokenClientCallback = (resp: google.accounts.oauth2.TokenResponse) => {
        if (resp.error !== undefined) {
            console.error("tokenResponse.error", resp.error);
            return;
        }

        sessionStorage.setItem('access', JSON.stringify({
            token: resp.access_token,
            expiresAt: Date.now() + Number(resp.expires_in) * 1000
        }));

        this.getProfile().catch(console.error);
    }
}

