import React from "react";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import WarningIcon from "@mui/icons-material/Error";
import Button from "@mui/joy/Button";
import { AppModal } from "./AppModal";

export const MapNewVersionDialog = (props: {
	onClose: () => void,
	onSubmit: () => Promise<void>,
	closeButtonName: string,
	confirmButtonName: string,
	textMessage: string,
}) => {

	return (
		<AppModal open={true} onClose={props.onClose}>
			<ModalDialog
				variant="plain"
				aria-labelledby="basic-modal-dialog-title"
				aria-describedby="basic-modal-dialog-description"
				sx={{
					maxWidth: 500,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>

				<WarningIcon color="warning" style={{marginBottom: "10px"}} />
				<Typography
					id="basic-modal-dialog-title"
					component="h2">
					{props.textMessage}
				</Typography>

				<Button
					autoFocus
					style={{marginBottom: "10px", minWidth: "100px"}}
					variant="outlined"
					onClick={() => {
						props.onClose();
					}}>
					{props.closeButtonName}
				</Button>
				<Button
					variant="outlined"
					style={{marginBottom: "10px", minWidth: "100px"}}
					color="danger"
					onClick={() => {
						props.onClose();
						void props.onSubmit();
					}
					}>
					{props.confirmButtonName}
				</Button>

			</ModalDialog>
		</AppModal>
	);
};