import {BASE_CELL_TYPES, IMap} from "./MapModel";

export interface IMapStats {
    totalCells: number;
    baseCells: number;
    baseCellsByType: number[];
    decorationCells: number;
    freeDecorationCells: number;
    reservedBaseCells: number[];
}

export class MapStats implements IMapStats {
    totalCells = 0;
    baseCells = 0;
    baseCellsByType = BASE_CELL_TYPES.map(() => 0);
    decorationCells = 0;
    freeDecorationCells = 0;
    reservedBaseCells: number[] = [];
    totalReservedBaseCells = 0;

    update(map: IMap, reservedBaseCellsPercents: number[]) {
        console.log("Calculating stats...");

        this.totalCells = map.tiles.length;
        this.baseCells = 0;
        this.decorationCells = 0;
        this.baseCellsByType = BASE_CELL_TYPES.map(() => 0);
        this.freeDecorationCells = 0;
        this.totalReservedBaseCells = 0;

        for (const tile of map.tiles) {
            if (tile.type.startsWith("b")) {
                const i = Number(tile.type.slice(1));
                this.baseCells++;
                this.baseCellsByType[i] = (this.baseCellsByType[i] || 0) + 1;
            } else {
                this.decorationCells++;
                this.freeDecorationCells += Number(!tile.img?.split(":")[1]);
            }
        }


        reservedBaseCellsPercents.forEach((percent, i) => {
            const amount = Math.floor(percent * this.totalCells / 100);
            this.reservedBaseCells[i] = amount;
            this.totalReservedBaseCells += amount;
        });

        this.decorationCells -= this.totalReservedBaseCells - this.baseCells;
        this.freeDecorationCells -= this.totalReservedBaseCells - this.baseCells;
    }

    addDecorations(amount: number) {
        this.freeDecorationCells -= amount;
    }
}