import {checkResource} from "../utils/resource_utils";
import {MapStats} from "./MapStats";

export const EMPTY_TILE = "g1_0";
export const BASE_CELL_TYPES = ["b0", "b1", "b2", "b3", "b4", "b5", "b6"];

export interface ITile {
    id: string,
    x: number,
    y: number,
    type: string,
    img?: string,
}

export interface ITileData {
    type: string,
    gnd: string,
    decor?: string,
}

export interface IMapProps {
    size: number,
    name: string,
    baseCellsByType?: number[],
    readOnlyBaseTiles?: boolean,
}

export interface IMap {
    properties: IMapProps,
    tiles: ITile[],
    timestamp?: number,
}

export class MapModel {
    private _tiles: ITile[][] = [];
    private _size: number = 0;
    private _name: string = "";
    private _baseCellsByType: number[] = BASE_CELL_TYPES.map(() => 0);
    private _timestamp: number = 0;
    private _readonlyBaseTiles: boolean = false;
    private _onChange: (props?: { minor: boolean }) => void = () => {};
    private _onChangeTile: (x: number, y: number) => void = () => {};
    private _onChangeStats: () => void = () => {};
    private _onResetMap: () => void = () => {};

    readonly stats = new MapStats();

    constructor()
    {}

    get size() {return this._size}

    get name() {return this._name}

    get readonlyBaseTiles() {return this._readonlyBaseTiles}

    get baseCellsByType() {return this._baseCellsByType}

    get tiles() {return this._tiles}

    set onChange(handler: (props?: { minor: boolean }) => void) {
        this._onChange = handler;
    }

    set onChangeTile(handler: (x: number, y: number) => void) {
        this._onChangeTile = handler;
    }

    set onChangeStats(handler: () => void) {
        this._onChangeStats = handler;
    }

    set onResetMap(handler: () => void) {
        this._onResetMap = handler;
    }

    canChangeTile(x: number, y: number, gnd: string, decor?: string, force = false): number {
        const data = this.getTileData(x, y);
        let change = 0;

        if (data.gnd !== gnd)
            change += 1;

        if (data.decor !== decor && (force || this.canChangeTileDecor(data)))
            change += 2;

        return change;
    }

    canChangeTileDecor(data: ITileData) {
        return !this.isTileReadonly(data) && (data.decor || this.stats.freeDecorationCells > 0);
    }

    setTile(x: number, y: number, gnd: string, decor?: string, force = false) {
        const change = this.canChangeTile(x, y, gnd, decor, force);
        if (!change)
            return;

        if (change > 1 && (!this.getTileData(x, y).decor || !decor)) {
            this.stats.addDecorations(decor ? 1 : -1);
            this._onChangeStats();
        }

        this.tiles[y][x].img = decor ? `${gnd}:${decor}` : gnd;
        this._onChangeTile(x, y);
        this._onChange({minor: true});
    }

    getTileData(x: number, y: number): ITileData {
        const tile = this.tiles[y][x];
        const [gnd, decor] = tile.img?.split(":") || [EMPTY_TILE];
        return {type: tile.type, gnd, decor};
    }

    isTileReadonly(tile: { type: string }) {
        return tile.type.startsWith('b');
    }

    initMap(size: number, name: string, baseTiles?: number[], readOnlyBaseTiles?: boolean) {
        this._tiles = [];
        this._size = size;
        this._name = name;
        this._readonlyBaseTiles = readOnlyBaseTiles || false;
        this._baseCellsByType = baseTiles || BASE_CELL_TYPES.map(() => 0);
        this.buildMap();
        this._onChange();
        this.updateStats();
    }

    buildMap() {
        const edge = this._size - 1;
        for (let y = edge; y >= -edge; y -= 1) {
            const row: ITile[] = [];
            this._tiles.push(row);

            for (let x = -edge; x <= edge; x += 1) {
                if ((x + y) % 2 === 0) {
                    row.push({id: makeLocId(x, y), x, y, type: "", img: EMPTY_TILE});
                }
            }
        }
        this._onResetMap();
    }

    setProps(props: Partial<IMapProps>) {
        let changed = false;

        if (props.size !== undefined && props.size !== this._size) {
            this.resize(props.size);
            changed = true;
        }

        if (props.name !== undefined && props.name !== this._name) {
            this._name = props.name;
            changed = true;
        }

        if (props.readOnlyBaseTiles !== undefined && props.readOnlyBaseTiles !== this._readonlyBaseTiles) {
            this._readonlyBaseTiles = props.readOnlyBaseTiles;
            changed = true;
        }

        if (props.baseCellsByType !== undefined) {
            const baseCellsByTypes = props.baseCellsByType;
            const isSomeChanged = baseCellsByTypes.some((v, i) => v !== this._baseCellsByType[i]);
            if (isSomeChanged) {
                this._baseCellsByType = baseCellsByTypes;
                changed = true;
                this.updateStats();
            }
        }

        if (changed)
            this._onChange();
    }

    setMap(map: IMap) {
        this._size = map.properties.size;
        this._name = map.properties.name;

        this._tiles = [];
        this._timestamp = map.timestamp ? map.timestamp : 0;
        let y = -this._size;
        let row: ITile[];
        map.tiles.forEach(tile => {
            if (tile.y !== y) {
                row = [];
                y = tile.y;
                this._tiles.unshift(row);
            }
            row.push(tile);
        });

        this._baseCellsByType = map.properties.baseCellsByType || BASE_CELL_TYPES.map(() => 0);
        this._readonlyBaseTiles = map.properties.readOnlyBaseTiles || false;

        this.updateStats();
        this._onChange();
        this._onResetMap();
    }

    importMap(map: IMap | ITile[], name: string) {
        if (Array.isArray(map)) {
            const size = (Math.sqrt(map.length * 2 - 1) + 1) / 2;
            if (size !== Math.floor(size) || size < 1)
                throw new Error("Invalid map size");

            this.setMap({properties: {size, name}, tiles: map.map(fixTileImg)});
        } else {
            const size = (Math.sqrt(map.tiles.length * 2 - 1) + 1) / 2;
            if (size !== Math.floor(size) || size < 1 || size !== map.properties.size)
                throw new Error("Invalid map size");

            this.setMap({properties: map.properties, tiles: map.tiles.map(fixTileImg)});
        }
    }

    updateTimeStamp() {
        this._timestamp = Date.now();
    }

    isNewVersionPresent(mapFromGoogleDrive: IMap) {
        return mapFromGoogleDrive.timestamp && mapFromGoogleDrive.timestamp > this._timestamp;
    }

    getMap(): IMap {
        const map: IMap = {
            properties: {
                size: this._size,
                name: this._name,
                baseCellsByType: this._baseCellsByType,
                readOnlyBaseTiles: this._readonlyBaseTiles,
            },
            tiles: [] as ITile[],
            timestamp: this._timestamp,
        }
        this._tiles.forEach(row => map.tiles.unshift(...row));
        return map;
    }

    generateBaseCells() {
        if (this.readonlyBaseTiles)
            return;

        const freeTilePositions: { x: number, y: number }[] = [];
        this.tiles.forEach((row, y) => row.forEach((tile, x) => {
            const isBaseTile = !tile.img?.split(":")[1];
            if (isBaseTile)
                freeTilePositions.push({x, y});
            else
                tile.type = "e";
        }));
        this._baseCellsByType.forEach((percent, i) => {
            const count = Math.floor(this.stats.totalCells * percent / 100);
            const type = BASE_CELL_TYPES[i];
            for (let j = 0; j < count; j++) {
                const pos = freeTilePositions.splice(Math.floor(Math.random() * freeTilePositions.length), 1)[0];
                this.tiles[pos.y][pos.x].type = type;
            }
        });
        this.updateStats();
        this._onChange();
    }

    clearBaseCells() {
        if (this.readonlyBaseTiles)
            return;

        this.tiles.forEach(row => row.forEach(tile => tile.type = ""));
        this.updateStats();
        this._onChange();
    }

    checkCells(): boolean {
        for (const row of this.tiles) {
            for (const tile of row) {
                const [gnd, decor] = tile.img?.split(":") || [];
                if (!checkResource("gnd", gnd) || decor && !checkResource("decor", decor))
                    return false;
            }
        }
        return true;
    }

    flipY() {
        const l = this._tiles.length - 1;
        for (let y = 0; y < this._size - 1; y++) {
            const row1 = this._tiles[y];
            const row2 = this._tiles[l - y];
            for (let x = 0; x < row1.length; x++) {
                const {y: tempY, id: tempId} = row1[x];
                row1[x].y = row2[x].y;
                row1[x].id = row2[x].id;
                row2[x].y = tempY;
                row2[x].id = tempId;
            }
        }
        this._onChange();
    }

    private resize(size: number) {
        const oldTiles = this._tiles.map(row => row.slice());
        this._tiles = [];
        this._size = size;
        this.buildMap();

        const offsetY = (oldTiles.length - this.tiles.length) / 2;
        for (let y = 0; y < this.tiles.length; y++) {
            const row = this.tiles[y];
            const oldRow = oldTiles[y + offsetY];
            if (!oldRow)
                continue;

            const offsetX = (oldRow.length - row.length) / 2;
            for (let x = 0; x < row.length; x++) {
                const tile = row[x];
                const oldTile = oldRow[x + offsetX];
                if (!oldTile)
                    continue;

                tile.img = oldTile.img;
                tile.type = oldTile.type;
            }
        }
        this.updateStats();
    }

    private updateStats() {
        this.stats.update(this.getMap(), this._baseCellsByType);
        this._onChangeStats();
    }
}

function makeLocId(x: number, y: number): string {
    const V = y < 0 ? "S" : y > 0 ? "N" : "";
    const H = x < 0 ? "W" : x > 0 ? "E" : "";
    const ux = x < 0 ? -x : x;
    const uy = y < 0 ? -y : y;
    return `${V}${uy}:${H}${ux}`;
}

function fixTileImg(tile: ITile) {
    if (!tile.img)
        tile.img = tile.type?.[0] === 'e' ? `${EMPTY_TILE}:loc_${tile.type}` : EMPTY_TILE;

    return tile;
}
