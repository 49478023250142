import React from "react";
import {app} from "../AppContext";
import {ITile} from "../map/MapModel";
import {checkResource} from "../utils/resource_utils";

const TILE_MODS = [
    [null, "_t"],
    [null, "_tl", "_tr"],
    ["_l", "_c", "_r"],
    [null, "_bl", "_br"],
    [null, "_b"],
];

interface IDraggingData {
    left: number,
    top: number,
    startX: number,
    startY: number,
}

export interface ITileMod {
    type: "decor" | "gnd";
    img: string;
}

export function Editor(props: {
    onSelectionChange: (selection: ITile[]) => void,
    tileMod?: ITileMod,
}) {
    const mapView = app.mapView;
    const mapEditor = app.mapEditor;
    const viewRef = React.useRef<HTMLDivElement>(null);
    const surfaceRef = React.useRef<HTMLDivElement>(null);
    const [left, setLeft] = React.useState(0);
    const [top, setTop] = React.useState(0);
    const [zoom, setZoom] = React.useState(1);
    const [dragging, setDragging] = React.useState<IDraggingData | undefined>(undefined);
    const [drag, setDragCursor] = React.useState(false);
    const [initTileCoords, setInitTileCoords] = React.useState<{ x: number, y: number } | undefined>(undefined);
    const [lastTileCoords, setLastTileCoords] = React.useState<{ x: number, y: number } | undefined>(undefined);
    const [startMoveCoords, setStartMoveCoords] = React.useState<{ x: number, y: number } | undefined>(undefined);

    React.useEffect(() => {
        app.keyCtrl.onStateChanges.addListener(handleKeyStateChange);
        document.addEventListener("mouseup", handleGlobalMouseUp);
        app.mapView.changeSelectionHandler = props.onSelectionChange;
        return () => {
            app.keyCtrl.removeKeypressListener(handleKeyStateChange);
            document.removeEventListener("mouseup", handleGlobalMouseUp);
        }
    }, []);

    React.useEffect(() => {
        surfaceRef.current && mapView.setSurface(surfaceRef.current);

        return () => {
            while (surfaceRef.current?.firstChild) {
                surfaceRef.current.removeChild(surfaceRef.current.firstChild);
            }
        }
    }, []);

    const handleKeyStateChange = () => {
        setDragCursor(app.keyCtrl.state.space);
    };

    const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
        if (app.keyCtrl.state.ctrl) {
            const {x, y} = viewRef.current?.getBoundingClientRect() || {x: 0, y: 0}
            zoomMap(zoom - e.deltaY / 1000, {x: e.clientX - x, y: e.clientY - y});
        } else {
            dragMap(left - e.deltaX, top - e.deltaY);
        }
    }

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        if (app.keyCtrl.state.space) {
            setDragging({left, top, startX: e.clientX, startY: e.clientY});
            return;
        }

        const coords = mapView.getTileCoords(getSurfaceCoords(e));
        if (!coords)
            return;

        if (app.keyCtrl.state.alt || app.keyCtrl.state.cmd) {
            if (!props.tileMod)
                return;

            setTile(coords);
            setInitTileCoords(coords);
        } else if (app.keyCtrl.state.shift) {
            setStartMoveCoords(coords);
            mapEditor.startMoveSelection();
        } else {
            mapView.selectTile(coords, false);
        }
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (dragging && app.keyCtrl.state.space) {
            dragMap(dragging.left + e.clientX - dragging.startX, dragging.top + e.clientY - dragging.startY);
            return;
        }

        const coords = mapView.getTileCoords(getSurfaceCoords(e));
        if (!coords || coords.x === lastTileCoords?.x && coords.y === lastTileCoords?.y)
            return;

        if ((app.keyCtrl.state.alt || app.keyCtrl.state.cmd) && initTileCoords && props.tileMod)
            setTile(coords);
        else if (app.keyCtrl.state.shift && startMoveCoords)
            mapEditor.moveSelection(startMoveCoords, coords, true);

        setLastTileCoords(coords);
    };

    const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
        if (app.keyCtrl.state.shift && !mapEditor.wasSelectionMoved) {
            const coords = mapView.getTileCoords(getSurfaceCoords(e));
            if (coords && coords.x === startMoveCoords?.x && coords.y === startMoveCoords?.y) {
                mapView.selectTile(coords, true);
            }
        } else if (mapEditor.wasSelectionMoved) {
            mapEditor.endMoveSelection();
        }
    }

    const handleGlobalMouseUp = () => {
        setDragging(undefined);
        setInitTileCoords(undefined);
        setStartMoveCoords(undefined);
    }

    const setTile = (coords: { x: number, y: number }) => {
        let tile = props.tileMod?.img;

        if (initTileCoords && props.tileMod && props.tileMod.img?.endsWith("_c")) {
            const dy = coords.y - initTileCoords.y;
            const dx = coords.x - initTileCoords.x + (dy % 2 ? (initTileCoords.y + 1) % 2 : 0);
            const mod = TILE_MODS[dy + 2]?.[dx + 1];
            if (!mod)
                return;

            tile = props.tileMod.img.replace(/_c$/, mod);
            if (!checkResource(props.tileMod.type, tile))
                return;
        }
        mapEditor.setTile(coords.x, coords.y, props.tileMod?.type || "decor", tile);
    }

    function zoomMap(newZoom: number, center: { x: number, y: number }) {
        newZoom = Math.min(2, Math.max(0.1, newZoom));

        const diffX = (center.x - left) * (newZoom / zoom - 1);
        const diffY = (center.y - top) * (newZoom / zoom - 1);
        dragMap(left - diffX, top - diffY);
        setZoom(() => {
            mapView.setZoom(newZoom);
            return newZoom;
        });
    }

    function dragMap(newX: number, newY: number) {
        if (!viewRef.current)
            return;

        const width = viewRef.current.clientWidth;
        const height = viewRef.current.clientHeight;
        const x = Math.min(0, Math.max(-mapView.width * zoom + width, newX));
        const y = Math.min(0, Math.max(-mapView.height * zoom + height, newY));
        setLeft(x);
        setTop(y);
    }

    function getSurfaceCoords(e: React.MouseEvent) {
        if (!viewRef.current)
            return {x: 0, y: 0};

        const bounds = viewRef.current?.getBoundingClientRect();
        const x = (e.clientX - bounds.x - left) / zoom;
        const y = (e.clientY - bounds.y - top) / zoom;
        return {x, y};
    }

    return <div
        ref={viewRef}
        onWheel={handleWheel}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        style={{
            flexGrow: 1,
            height: "100%",
            overflow: "hidden",
            userSelect: "none",
            ...drag ? {cursor: "grab"} : {},
        }}
    >
        <div
            ref={surfaceRef}
            className="surface"
            style={{
                pointerEvents: "none",
                position: "relative",
                left: left,
                top: top,
            }}
        />
    </div>;
}
