import { ModalDialog } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import ErrorIcon from "@mui/icons-material/Error";
import React from "react";
import Button from "@mui/joy/Button";
import { AppModal } from "./AppModal";

export const ErrorDialog = (props: {
	onClose: () => void,
	textMessage: string,
}) => {

	return (
		<AppModal open={true} onClose={props.onClose}>
			<ModalDialog
				variant="plain"
				aria-labelledby="basic-modal-dialog-title"
				aria-describedby="basic-modal-dialog-description"
				sx={{
					maxWidth: 500,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>

				<ErrorIcon color="error" style={{marginBottom: "10px"}} />
				<Typography
					id="basic-modal-dialog-title"
					component="h2">
					{props.textMessage}
				</Typography>

				<Button
					autoFocus
					style={{marginBottom: "10px"}}
					variant="outlined"
					onClick={props.onClose}>
					OK
				</Button>
			</ModalDialog>
		</AppModal>
	);
};