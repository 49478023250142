import {KeyboardArrowDown} from "@mui/icons-material";
import {
    Box,
    IconButton,
    List,
    listClasses,
    ListItem,
    ListItemButton,
    ListItemContent,
    ListItemDecorator,
    Sheet
} from "@mui/joy";
import React from "react";
import {app} from "../AppContext";

type Tile = string | string[];

export function TilesList(props: {
    type: "gnd" | "decor",
    tiles: (string | string[])[],
    onSelect: (tile: string) => void
}) {

    const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(undefined);
    const [selectedSubIndex, setSelectedSubIndex] = React.useState<number | undefined>(undefined);
    const [openedItems, setOpenedItems] = React.useState<Set<number>>(new Set<number>());
    const [selectedTile, setSelectedTile] = React.useState<string | undefined>(undefined);

    const handleSelectItem = (index: number) => {
        setSelectedIndex(index);
        setSelectedSubIndex(undefined);

        const tile = getTile(props.tiles[index]);
        setSelectedTile(tile);
        props.onSelect(tile);
    }

    const handleSelectSubItem = (index: number, subIndex: number) => {
        setSelectedIndex(index);
        setSelectedSubIndex(subIndex);

        const tile = props.tiles[index][subIndex];
        setSelectedTile(tile);
        props.onSelect(tile);
    }

    const handleTileDoubleClick = () => {
        if (selectedTile)
            app.mapEditor.setSelectedTiles(props.type, selectedTile);
    }

    function getTile(tile: Tile): string {
        if (typeof tile === "string") {
            return tile;
        } else {
            return tile.find((t: string) => t.endsWith("_c") || t.endsWith("_0")) || tile[0];
        }
    }

    function getTileName(tile: Tile): string {
        if (typeof tile === "string") {
            return tile;
        } else {
            const cTile = tile.find((t: string) => t.endsWith("_c") || t.endsWith("_0")) || "";
            return cTile.replace(/_c|_0$/, "");
        }
    }

    function getTileImg(tile: Tile): string {
        if (typeof tile === "string") {
            return tile;
        } else {
            const cTile = tile.find((t: string) => t.endsWith("_c") || t.endsWith("_0")) || "";
            return cTile.replace(/_c|_0$/, "") + "/" + cTile;
        }
    }

    return (
        <Sheet sx={{
            overflow: "scroll",
            overflowX: "hidden",
            height: "100%",
            // border: "1px solid",
            borderColor: "divider",
        }}>
            <List sx={{
                '--List-item-minHeight': '70px',
                '--List-nestedInsetStart': '0px',
                '--List-item-paddingLeft': '2px',
                [`& .${listClasses.nesting}`]: {
                    borderLeft: '2px solid',
                    borderColor: 'divider',
                    marginLeft: "14px",
                    paddingLeft: "1px",
                },
                width: "280px",
                padding: 0,
            }}>
                {props.tiles.map((tile, index) => <ListItem
                    key={index}
                    sx={index === 0 ? {
                        position: "sticky", top: 0, pt: 0, zIndex: 2,
                        background: "var(--joy-palette-background-surface)"
                    } : {}}
                    nested={typeof tile !== "string"}
                    startAction={typeof tile !== "string" && tile.length > 1 ? <IconButton
                        variant="plain"
                        size="sm"
                        color="neutral"
                        sx={{margin: "1px"}}
                        onClick={() => {
                            openedItems.has(index) ? openedItems.delete(index) : openedItems.add(index);
                            setOpenedItems(new Set(openedItems));
                        }}>
                        <KeyboardArrowDown sx={{transform: openedItems.has(index) ? 'initial' : 'rotate(-90deg)'}}/>
                    </IconButton> : undefined}
                    endAction={typeof tile !== "string" && tile.length > 1 && <TileGroupShape tile={tile}/>}
                >{typeof tile === "string"
                    ? <TileButton type={props.type} name={getTileName(tile)} img={getTileImg(tile)}
                                  selected={index === selectedIndex}
                                  onClick={() => handleSelectItem(index)}
                                  onDoubleClick={handleTileDoubleClick}
                    />
                    : <ListItemContent>
                        <TileButton type={props.type} name={getTileName(tile)} img={getTileImg(tile)}
                                    selected={index === selectedIndex}
                                    onClick={() => handleSelectItem(index)}
                                    onDoubleClick={handleTileDoubleClick}
                        />
                        {openedItems.has(index) && <List>
                            {tile.map((t, i) => !t.endsWith('_c') && !t.endsWith('_0') &&
                                <ListItem key={i} sx={{pt: 0}}>
                                    <TileButton type={props.type} name={t} img={`${getTileName(tile)}/${t}`}
                                                selected={index === selectedIndex && i === selectedSubIndex}
                                                onClick={() => handleSelectSubItem(index, i)}
                                                onDoubleClick={handleTileDoubleClick}
                                    />
                                </ListItem>)}
                        </List>}
                    </ListItemContent>
                }</ListItem>)}
            </List>
        </Sheet>
    );
}

function TileButton(props: {
    type: "gnd" | "decor", name: string, img: string, selected: boolean,
    onClick: () => void,
    onDoubleClick: () => void,
}) {
    const resName = props.type === "decor" ? "tiles" : "gnd";

    return (
        <ListItemButton sx={{pl: 3}}
                        variant={props.selected ? 'soft' : undefined}
                        onClick={props.onClick}
                        onDoubleClick={props.onDoubleClick}
        >
            <ListItemDecorator>
                <img width={100} src={`/resources/${resName}/${props.img}.png`}
                     alt={props.name}/>
            </ListItemDecorator>
            <ListItemContent sx={{pl: 1}}>
                {props.name}
            </ListItemContent>
        </ListItemButton>
    )
}

function TileGroupShape(props: { tile: string[] }) {

    const hasPos = (pos: "tl" | "t" | "tr" | "l" | "c" | "r" | "bl" | "b" | "br") => {
        return props.tile.find(t => t.endsWith("_" + pos)) !== undefined;
    }

    const shape = [
        [hasPos("t"), hasPos("tr"), hasPos("r")],
        [hasPos("tl"), hasPos("c"), hasPos("br")],
        [hasPos("l"), hasPos("bl"), hasPos("b")],
    ]

    return (
        <Box sx={{transform: 'rotate(45deg)', mr: 1}}>
            {shape.map((row, r) => <Box key={r} sx={{display: "flex"}}>
                {row.map((col, c) => <Box key={c} sx={(theme) => ({
                    width: "8px",
                    height: "8px",
                    backgroundColor: col ? theme.vars.palette.neutral.plainColor : theme.vars.palette.neutral.softActiveBg,
                })}/>)}
            </Box>)}
        </Box>
    )
}
