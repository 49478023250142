import { List, ListItem, ListItemButton, Stack } from "@mui/joy";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { app } from "../AppContext";
import { AppModal } from "./AppModal";
import { CloseButton } from "./CloseButton";

type TFile = gapi.client.drive.File;
type TGroup = "prod" | "dev";

export function LoadMapDialog(props: {
	onSubmit: (id: string) => Promise<void>,
	onClose: () => void
})
{
	const [files, setFiles] = React.useState<TFile[]>([]);

	React.useEffect(() => {
		loadFiles().catch(e => console.error(e));
	}, []);

	function makeFileList(files: TFile[]): TFile[]
	{
		const result: TFile[] = [];
		const sorted = files.sort(
			(a, b) => (a.name ?? "").localeCompare(b.name ?? ""),
		);

		let group: TGroup = "prod";

		for (const f of sorted) {
			const name = f.name ?? "unnamed";
			const isDevFile = name.startsWith("x_");
			if (group === "prod" && isDevFile) {
				group = "dev";
				result.push({
					id: "sep",
					name: "dev files",
				});
			}
			result.push(f);
		}

		return result;
	}

	const loadFiles = async () => {
		try {
			const files = await app.googleDriveService.loadFilesOfMaps();
			if (files)
				setFiles(makeFileList(files));
		} catch (e) {
			console.error(e);
			app.showError("Failed to load maps", e);
			props.onClose();
		}
	};

	return (
		<AppModal open={true} onClose={() => props.onClose()}>
			<ModalDialog
				aria-labelledby="basic-modal-dialog-title"
				aria-describedby="basic-modal-dialog-description"
				sx={{maxWidth: 500}}
			>
				<CloseButton onClick={props.onClose} />
				<Typography id="basic-modal-dialog-title" component="h2">
					Open map
				</Typography>
				<List>
					{
						files.map(file =>
							<ListItem key={file.id} sx={{flexGrow: 1}}>
								{
									file.id === "sep"
										? <Stack sx={{
											flexGrow: 1,
											color: "gray",
											alignItems: "start"
										}}>
											<span>:: {file.name}</span>
										</Stack>
										: <ListItemButton onClick={() => {
											props.onClose();
											void props.onSubmit(file.id!);
										}}>
											<Typography fontFamily="monospace, monospace">
												{file.name}
											</Typography>
										</ListItemButton>
								}
							</ListItem>)
					}
				</List>
			</ModalDialog>
		</AppModal>
	);
}

