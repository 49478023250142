import {CheckCircle, CloseRounded, Info, Report, Warning} from "@mui/icons-material";
import {Alert, IconButton, Typography} from "@mui/joy";
import {AlertPropsColorOverrides} from "@mui/joy/Alert/AlertProps";
import {ColorPaletteProp} from "@mui/joy/styles/types";
import {OverridableStringUnion} from "@mui/types";
import React from "react";

export enum EAlertSeverity {
    error = 'error',
    warning = 'warning',
    info = 'info',
    success = 'success',
}

interface IVariant {
    title: string,
    color: OverridableStringUnion<ColorPaletteProp, AlertPropsColorOverrides>,
    icon: React.ReactElement,
}

const variants: { [key: string]: IVariant } = {
    error: {title: 'Error', color: 'danger', icon: <Report/>},
    warning: {title: 'Warning', color: 'warning', icon: <Warning/>},
    info: {title: 'Info', color: 'info', icon: <Info/>},
    success: {title: 'Success', color: 'success', icon: <CheckCircle/>},
}

export function AppAlert(props: {
    open: boolean,
    msg: string,
    severity: EAlertSeverity,
    autoCloseDuration: number,
    onClose: () => void
}) {

    const variant = variants[props.severity];
    const [open, setOpen] = React.useState(props.open);

    React.useEffect(() => {
        setOpen(props.open);
        if (props.open && props.autoCloseDuration > 0) {
            setTimeout(props.onClose, props.autoCloseDuration);
        }
    }, [props.open]);

    return (
        <Alert
            sx={{
                display: open ? 'flex' : 'none',
                position: 'absolute',
                margin: '8px',
                top: 0,
                right: 0,
                width: 'calc(100% - 16px)',
                maxWidth: '400px',
                alignItems: 'flex-start',
                zIndex: 2,
            }}
            startDecorator={React.cloneElement(variant.icon, {
                sx: {mt: '2px', mx: '4px'},
                fontSize: 'xl2',
            })}
            variant="soft"
            color={variant.color as OverridableStringUnion<ColorPaletteProp, AlertPropsColorOverrides>}
            endDecorator={
                <IconButton variant="soft" size="sm" color={variant.color} onClick={props.onClose}>
                    <CloseRounded/>
                </IconButton>
            }
        >
            <div>
                <Typography fontWeight="lg" mt={0.25}>
                    {variant.title}
                </Typography>
                <Typography fontSize="sm" sx={{opacity: 0.8}}>
                    {props.msg}
                </Typography>
            </div>
        </Alert>
    );
}