import {ArrowDropDown} from "@mui/icons-material";
import {ButtonPropsColorOverrides, ButtonPropsSizeOverrides, IconButton, Menu, MenuItem, Stack} from "@mui/joy";
import Button from "@mui/joy/Button";
import {ButtonPropsVariantOverrides} from "@mui/joy/Button/ButtonProps";
import {ColorPaletteProp, VariantProp} from "@mui/joy/styles/types";
import {OverridableStringUnion} from "@mui/types";
import React from "react";

interface IOptionButton {
    label: string,
    onClick: () => void,
    disabled?: boolean,
}

export function ComboButton(props: {
    onClick?: () => void,
    children?: React.ReactNode,
    options: (IOptionButton | JSX.Element)[],
    variant?: OverridableStringUnion<VariantProp, ButtonPropsVariantOverrides>,
    color?: OverridableStringUnion<ColorPaletteProp, ButtonPropsColorOverrides>,
    size?: OverridableStringUnion<'sm' | 'md' | 'lg', ButtonPropsSizeOverrides>,
    disabled?: boolean,
    sx?: React.CSSProperties,
}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget.parentElement);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOptionClick = (option: { label: string, onClick: () => void }) => {
        option.onClick();
        handleClose();
    }

    return (
        <React.Fragment>
            <Stack direction="row">
                <Button
                    variant={props.variant}
                    color={props.color}
                    size={props.size}
                    disabled={props.disabled}
                    sx={{...props.sx, borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                    onClick={props.onClick}
                >
                    {props.children}
                </Button>
                <IconButton size="sm" variant="solid" onClick={handleClick}
                            sx={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}>
                    <ArrowDropDown/>
                </IconButton>
            </Stack>
            <Menu
                id="combo-button-menu"
                anchorEl={anchorEl}
                placement="bottom-start"
                open={open}
                onClose={handleClose}
                sx={{padding: 0, margin: 0}}
            >
                {props.options.map((option, i) => (
                    (option as IOptionButton).label
                        ? <MenuItem
                            key={i}
                            sx={{padding: 0, margin: 0}}
                            disabled={(option as IOptionButton).disabled}
                            onClick={() => handleOptionClick((option as IOptionButton))}>
                            <Button
                                sx={props.options.length === 1 ? {} : i === 0
                                    ? {borderBottomLeftRadius: 0, borderBottomRightRadius: 0}
                                    : i === props.options.length - 1
                                        ? {borderTopLeftRadius: 0, borderTopRightRadius: 0}
                                        : {borderRadius: 0}}
                                variant={props.variant}
                                color={props.color}
                                size={props.size}
                                disabled={(option as IOptionButton).disabled}
                                fullWidth
                            >
                                {(option as IOptionButton).label}
                            </Button>
                        </MenuItem>
                        : <MenuItem key={i}>
                            {option as JSX.Element}
                        </MenuItem>)
                )}
            </Menu>
        </React.Fragment>
    );
}